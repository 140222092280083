/**
 * IMPORTS
 */

import { AspectRatio, Image, Box, Button, Center, Stack, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { FaCheck } from 'react-icons/fa';

import { useTranslation } from '../../utils/translate';
import FallbackImage from '../common/FallbackImage';

/**
 * CORE
 */

interface OverlayProps {
  themeName: string;
  onConfirmClick: React.ComponentProps<typeof Button>['onClick'];
}

function Overlay({
  themeName,
  onConfirmClick,
}: OverlayProps) {
  const { t } = useTranslation();

  return (
    <Box
      position="absolute"
      h="100%"
    >
      <Center h="100%">
        <Stack>
          <Text color="white" align="center">
            {t([`theme_${themeName}.confirm`, 'theme.confirm'])}
          </Text>
          <Button
            size="lg"
            colorScheme="brand"
            leftIcon={<FaCheck />}
            onClick={onConfirmClick}
          >
            {t([`theme_${themeName}.confirm-button`, 'theme.confirm-button'])}
          </Button>
        </Stack>
      </Center>
    </Box>
  )
}

interface TOCProps {
  themeName: string;
  imageUrl: string;
  value: string;
  isSelected: boolean;
  onSelect: (value: string) => any;
  onConfirm: (value: string) => any;
}

function ThemeOptionComponent({
  themeName,
  imageUrl,
  value,
  isSelected,
  onSelect,
  onConfirm,
}: TOCProps) {
  const handleClick = useCallback(() => onSelect(value), [value, onSelect]);
  const handleConfirmClick = useCallback(() => onConfirm(value), [value, onConfirm]);

  const defaultStyle = {
    transform: 'scale(1)',
    filter: 'brightness(100%)',
  };

  const selectedStyle = {
    transform: 'scale(1.25)',
    filter: 'brightness(33%)',
  };

  return (
    <AspectRatio
      ratio={16/9}
    >
      <Box overflow="hidden">
        <Image
          src={imageUrl}
          alt={value}
          fallback={<FallbackImage />}
          style={{
            transition: 'all 200ms',
            ...(isSelected ? selectedStyle : defaultStyle),
          }}
          onClick={handleClick}
        />

        {isSelected && (
          <Overlay
            themeName={themeName}
            onConfirmClick={handleConfirmClick}
          />
        )}
      </Box>
    </AspectRatio>
  )
}

export default ThemeOptionComponent;
