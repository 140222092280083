/**
 * IMPORTS
 */

import { extendTheme } from '@chakra-ui/react';

/**
 * CORE
 */

// @see https://smart-swatch.netlify.app/ (get JS object)
const colors = {
  brand: {
    50: "#f8f9e3",
    100: "#ebeac2",
    200: "#dedda0",
    300: "#d1cf7b",
    400: "#c3c157",
    500: "#aaa73e",
    600: "#84822e",
    700: "#5e5d20",
    800: "#393810",
    900: "#141400"
  },
  snapchat: {
    50: "#403F00",
    100: "#666500",
    200: "#8C8B00",
    300: "#B3B000",
    400: "#D9D600",
    500: "#FFFC0D",
    600: "#FFFD33",
    700: "#FFFD59",
    800: "#FFFE80",
    900: "#FFFEA6"
  },
  tiktok: {
    50: "#000000",
    100: "#000000",
    200: "#000000",
    300: "#000000",
    400: "#000000",
    500: "#000000",
    600: "#262626",
    700: "#404040",
    800: "#595959",
    900: "#737373"
  },
};

const fonts = {
  body: '"Open Sans",Arial,sans-serif',
  heading: 'Oswald,Helvetica,Arial,sans-serif',
};

const Button = {
  baseStyle: {
    borderRadius: 'full',
  },
};

const Heading = {
  baseStyle: {
    color: 'brand.500',
    textTransform: 'uppercase',
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button,
    Heading,
  },
});

export default theme;
