/**
 * IMPORTS
 */

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AspectRatio, Box, Button, Center, Container, Heading,
  Icon, Stack, Text, Image, CircularProgress, useToast, Flex } from '@chakra-ui/react';
import Webcam from 'react-webcam';
import { MdCameraAlt, MdErrorOutline } from 'react-icons/md';

import { useTranslation } from '../../utils/translate';
import CaptureContext from './CaptureContext';

import frameRef from '../../assets/frame-ref.png';
import Delayed from '../common/Delayed';

/**
 * CORE
 */

interface CaptureStepProps {
  onTakePhoto: (dataUri: string) => any;
}

function CaptureStep({
  onTakePhoto,
}: CaptureStepProps) {
  const [started, setStarted] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [takePhoto, setTakePhoto] = useState(false);
  const [streamStarted, setStreamStarted] = useState(false);
  const [streamFailed, setStreamFailed] = useState(false);
  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);

  const toast = useToast();

  const { t } = useTranslation();

  const { captureIndex, expectedCount } = useContext(CaptureContext);

  const startCountdown = useCallback(() => setStarted(true), []);

  useEffect(() => {
    if (!streamStarted || streamFailed || started) return;
    startCountdown();
  }, [streamStarted, streamFailed, started, startCountdown]);

  useEffect(() => {
    if (!started) return;

    if (countdown > 0) {
      const to = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(to);
    }

    setTakePhoto(true);
  }, [started, countdown]);

  useEffect(() => {
    if (takePhoto) {
      setTakePhoto(false);
      
      const webcam = webcamRef.current;
      if (!webcam) {
        alert('Camera is not initiliazed');
        return;
      }

      const { videoWidth = 100, videoHeight = 100 } = webcam.video || {};
      const ratio = videoHeight / videoWidth;
      const maxW = Math.min(videoWidth, 1080);
      const dimensions = { width: maxW, height: Math.round(maxW * ratio) };

      const dataUri = webcam.getScreenshot(dimensions);
      if (dataUri) {
        onTakePhoto(dataUri);
      }
    }
  }, [takePhoto, webcamRef, onTakePhoto]);

  const onUseMediaError = () => {
    setStreamFailed(true);
    toast({
      title: t('capture.initialize.error-toast.title') as string,
      description: t('capture.initialize.error-toast.description') as string,
      status: 'error',
      duration: null,
      isClosable: true,
    });
  };

  return (
    <Stack spacing="1rem">
      <Box
        position="relative"
        bgColor="grey"
      >
        <AspectRatio ratio={1}>
          <Webcam
            ref={webcamRef}
            audio={false}
            mirrored
            screenshotFormat="image/jpeg"
            // screenshotQuality={1}
            videoConstraints={{
              facingMode: "user",
              width: { ideal: 4096 },
            }}
            onUserMedia={() => setStreamStarted(true)}
            onUserMediaError={onUseMediaError}
          />
        </AspectRatio>

        <Box
          position="absolute"
          w="100%"
          h="100%"
          top={0}
          left={0}
        >
          <Image src={frameRef} opacity={0.5} />
        </Box>

        {started && countdown > 0 && (
          <Box
            position="absolute"
            w="100%"
            bottom="2rem"
            left={0}
          >
            <Center h="100%">
              <Heading
                size="4xl"
                color="white"
                textShadow="0 0 5px black"
              >
                {countdown}
              </Heading>
            </Center>
          </Box>
        )}
      </Box>

      <Container>
        <Flex
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          fontSize="lg"
          fontWeight={600}
          height="3rem"
        >
          {started && (
            <>
              <Icon
                as={MdCameraAlt}
                marginRight="0.5rem"
                w={6}
                h={6}
              />
              <Text>
                {t(['capture.capturing'], { current: captureIndex, total: expectedCount })}
              </Text>
            </>
          )}
          {!started && streamStarted && (
            <Button
              size="lg"
              colorScheme="brand"
              isFullWidth
              onClick={startCountdown}
              leftIcon={<MdCameraAlt />}
              disabled={started}
            >
              {t([`capture_${captureIndex}.capture-button`, 'capture.capture-button'])}
            </Button>
          )}
          {!streamStarted && !streamFailed && (
            <Delayed delay={500}>
              <Flex>
                <CircularProgress
                  size={8}
                  marginRight="0.5rem"
                  color="brand.500"
                  isIndeterminate
                />
                <Text
                  color="brand.500"
                >
                  {t('capture.initialize.initializing')}
                </Text>
              </Flex>
            </Delayed>
          )}
          {!streamStarted && streamFailed && (
            <>
              <Icon
                as={MdErrorOutline}
                color="red.500"
                marginRight="0.5rem"
                w={6}
                h={6}
              />
              <Text color="red.500">
                {t('capture.initialize.failed')}
              </Text>
            </>
          )}
        </Flex>
      </Container>
    </Stack>
  );
}

export default CaptureStep;
