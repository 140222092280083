/**
 * IMPORTS
 */

import {
  all,
  takeEvery,
  put,
  take,
  call,
  select,
  delay,
} from 'redux-saga/effects';
import { Action } from 'redux';
import b64ToBlob from 'b64-to-blob';
import ky from 'ky';

import {
  AppActionType,
  createSession,
  createSessionFailure,
  createSessionSuccess,
  notifyCompletionFailure,
  NotifyCompletionRequestAction,
  notifyCompletionSuccess,
  UploadRequestAction,
  UploadSuccessAction,
  uploadSuccess,
  CreateSessionSuccessAction,
  CreateSessionFailureAction,
  upload,
  notifyCompletion,
  CreateSessionRequestAction,
  uploadFailure,
  ValidatePhotoAction,
  ContentListRequestAction,
  getContentListSuccess,
  getContentListFailure,
  PushThemeAction,
  getContentList,
  ContentListSuccessAction,
  ContentListFailureAction,
  RequestAction,
  FailureAction,
  isFailureAction,
  isResponseAction,
  RequestActionType,
  CoreRequestActionType,
  uploadTheme,
  UploadThemeRequestAction,
  uploadThemeSuccess,
  uploadThemeFailure,
} from '../actions';

import { UploadInfo } from '../types';
import {
  getCallbackUrl,
  getExpectedCount,
  getPhotoUri,
  getPickedContent,
  getPickedProductId,
  getPickedThemes,
  getStatusUrl,
  getThemes,
  getUploadedCount,
  getUploadInfos,
  getUploadThemeUrl,
  getValidatedCount,
} from '../selectors';

/**
 * GLOBALS
 */

const {
  NODE_ENV = 'development',
} = process.env;

const API_URL = NODE_ENV === 'production' ? '/api' : 'http://localhost:8080/api';

/**
 * UTILS
 */

function* handleApiError<T extends CoreRequestActionType, U>(
  requestAction: RequestAction<T, U>,
  err: any,
  failureAction: (requestAction: RequestAction<T, U>, code: number, message: string) => FailureAction<RequestAction<T, U>>,
) {
  console.error(err);

  let code = 0;
  let message = err.message
  if (err instanceof ky.HTTPError) {
    const { response: r } = err;
    const body = yield call(r.json.bind(r));
    code = body.code || r.status;
    message = body.message || err.message;
  }
  yield put(failureAction(requestAction, code, message));
}

/**
 * ACTUAL REQUESTS
 */

async function asyncCreateSession() {
  return ky.post(`${API_URL}/create`).json();
}

async function asyncNotifyCompletion(callbackUrl: string) {
  return ky.post(`${API_URL}${callbackUrl}`, { timeout: 30000 }).json();
}

async function asyncUploadTheme(uploadThemeUrl: string, theme: string) {
  return ky.post(`${API_URL}${uploadThemeUrl}&theme=${theme}`, { timeout: 30000 }).json();
}

async function asyncAwsUpload(dataUri: string, info: UploadInfo) {
  const [header, b64Content] = dataUri.split(';base64,');
  const [, imageType] = header.split(':');
  const blob = b64ToBlob(b64Content, imageType);
  const formData = new FormData();
  Object.keys(info.fields).forEach((k) => formData.append(k, info.fields[k]));
  formData.append('file', blob, 'selfie.jpg');
  return ky.post(info.url, { body: formData });
}

async function asyncGetContentList(url: string) {
  return ky.get(url).json();
}

/**
 * CORE: REQUESTS
 */

function* onCreateSessionRequest(action: CreateSessionRequestAction) {
  try {
    const { data } = yield call(asyncCreateSession);
    yield put(createSessionSuccess(action, data.id, data.forms, data.callback));
  } catch (err) {
    yield handleApiError(action, err, createSessionFailure);
  }
}

function* onUploadRequest(action: UploadRequestAction) {
  try {
    yield call(asyncAwsUpload, action.content, action.uploadInfo);
    yield put(uploadSuccess(action));
  } catch (err) {
    console.error(err);

    let code = 0;
    let message = err.message
    if (err instanceof ky.HTTPError) {
      const { response: r } = err;
      code = r.status;
    }
    yield put(uploadFailure(action, code, message));
  }
}

function* onNotifyCompletionRequest(action: NotifyCompletionRequestAction) {
  try {
    const { data } = yield call(asyncNotifyCompletion, action.callbackUrl);
    yield put(notifyCompletionSuccess(action, data.image, data.status, data.uploadTheme));
  } catch (err) {
    yield handleApiError(action, err, notifyCompletionFailure);
  }
}

function* onUploadThemeRequest(action: UploadThemeRequestAction) {
  try {
    yield call(asyncUploadTheme, action.uploadThemeUrl, action.theme);
    yield put(uploadThemeSuccess(action));
  } catch (err) {
    yield handleApiError(action, err, uploadThemeFailure);
  }
}

function* onContentListRequest(action: ContentListRequestAction) {
  try {
    const { data } = yield call(asyncGetContentList, action.statusUrl);
    yield put(getContentListSuccess(action, data));
  } catch (err) {
    yield handleApiError(action, err, getContentListFailure);
  }
}

function* onContentListSuccess(action: ContentListSuccessAction) {
  const content = yield select(getPickedContent);
  if (!content) {
    yield delay(2000);
    const url = yield select(getStatusUrl);
    yield put(getContentList(url));
  }
}

function* onContentListFailure(action: ContentListFailureAction) {
  yield delay(2000);
  const url = yield select(getStatusUrl);
  yield put(getContentList(url));
}

/**
 * CORE: APP
 */

function* onValidatePhoto(action: ValidatePhotoAction) {
  try {
    const index: ReturnType<typeof getValidatedCount> = yield select(getValidatedCount);

    // create session if it's first photo to be validated
    if (index === 1) {
      const uiAction = createSession()
      yield put(uiAction);

      const uiResAction: CreateSessionSuccessAction | CreateSessionFailureAction = yield take(takeResponse(uiAction));
      if (isFailureAction(uiResAction)) {
        throw new Error(`${uiResAction.code}: ${uiResAction.message}`);
      }
    }

    // perform actual upload
    const photoUri: ReturnType<typeof getPhotoUri> = yield select(getPhotoUri);
    const uploadInfos: ReturnType<typeof getUploadInfos> = yield select(getUploadInfos);
    const uAction = upload(photoUri!, uploadInfos[index - 1]);
    yield put(uAction);
  } catch (err) {
    // TODO
    console.error(err);
  }
}

const takeResponse = (requestAction: RequestAction) => (action: Action) =>  {
  return isResponseAction(action) && action.requestAction.requestId === requestAction.requestId;
}

function* onUploadSuccess(action: UploadSuccessAction) {
  const expected: ReturnType<typeof getExpectedCount> = yield select(getExpectedCount);
  const uploaded: ReturnType<typeof getUploadedCount> = yield select(getUploadedCount);

  // notify completion if we uploaded all files
  if (uploaded === expected) {
    const callbackUrl: ReturnType<typeof getCallbackUrl> = yield select(getCallbackUrl);
    const nAction = notifyCompletion(callbackUrl!);
    yield put(nAction);
  }
}

function* onPushTheme(action: PushThemeAction) {
  const picked = yield select(getPickedThemes);
  const themes = yield select(getThemes);
  if (picked.length === themes.length) {
    const url = yield select(getStatusUrl);

    const uploadThemeUrl: ReturnType<typeof getUploadThemeUrl> = yield select(getUploadThemeUrl);
    if (uploadThemeUrl) {
      const productId: ReturnType<typeof getPickedProductId> = yield select(getPickedProductId);
      const uploadAction = uploadTheme(uploadThemeUrl, productId)
      yield put (uploadAction)
    }

    yield put(getContentList(url));
  }
}

function* rootSaga() {
  yield all([
    takeEvery(RequestActionType.CREATE_SESSION.REQUEST,    onCreateSessionRequest),
    takeEvery(RequestActionType.AWS_UPLOAD.REQUEST,        onUploadRequest),
    takeEvery(RequestActionType.AWS_UPLOAD.SUCCESS,        onUploadSuccess),
    takeEvery(RequestActionType.NOTIFY_COMPLETION.REQUEST, onNotifyCompletionRequest),
    takeEvery(RequestActionType.UPLOAD_THEME.REQUEST, onUploadThemeRequest),
    takeEvery(RequestActionType.CONTENT_LIST.REQUEST,      onContentListRequest),
    takeEvery(RequestActionType.CONTENT_LIST.SUCCESS,      onContentListSuccess),
    takeEvery(RequestActionType.CONTENT_LIST.FAILURE,      onContentListFailure),
    takeEvery(AppActionType.VALIDATE_PHOTO,                onValidatePhoto),
    takeEvery(AppActionType.PUSH_THEME,                    onPushTheme),
  ]);
}

export default rootSaga;
